import { Box, Stack, Text } from '@chakra-ui/react'
import NextLink from 'next/link'

import { UpgradeAlertButtonPrimary } from './UpgradeAlertButtonPrimary'
import { UpgradeAlertContainer } from './UpgradeAlertContainer'
import { UpgradeAlertTitle } from './UpgradeAlertTitle'

export const LoginToAskAlert = ({
  questionsRemaining,
}: {
  questionsRemaining: number
}) => {
  return (
    <UpgradeAlertContainer>
      <Box flexGrow={1} width="100%">
        <UpgradeAlertTitle>
          <Text as="span">
            You have <b>{questionsRemaining}</b> question
            {questionsRemaining === 1 ? '' : 's'} left today.
          </Text>
          <Text as="span">
            {' '}
            Sign in to unlock more questions, access your history and more.
          </Text>
        </UpgradeAlertTitle>
      </Box>
      <Stack direction={['column', 'row']} flex={1} spacing={3} width="100%">
        <UpgradeAlertButtonPrimary as={NextLink} href="/auth/sign-in">
          Sign In
        </UpgradeAlertButtonPrimary>
      </Stack>
    </UpgradeAlertContainer>
  )
}
