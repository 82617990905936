import { trpc } from '@/lib/trpcClient'
import { useSessionContext } from '@supabase/auth-helpers-react'

export function useProfile() {
  const sessionContext = useSessionContext()

  const profileQuery = trpc.profiles.currentUser.useQuery(undefined, {
    enabled: sessionContext.session !== null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    // the difference between fetch and load is that load returns true when the query is disabled 🤷‍♂️
    // fetch also fires even when the data is cached
    isFetchingProfile: profileQuery.isFetching,
    isLoadingProfile: profileQuery.isLoading,
    isLoadingSession: sessionContext.isLoading,
    isLoggedIn:
      sessionContext.session !== null &&
      !sessionContext.isLoading &&
      !sessionContext.session.user.is_anonymous,
    isLoggedOut:
      (sessionContext.session === null ||
        sessionContext.session.user.is_anonymous) &&
      !sessionContext.isLoading,
    profile: profileQuery.data,
  }
}
