import type { Session as SupabaseSession } from '@supabase/auth-helpers-react'
import type { AppProps } from 'next/app'

import '@/assets/globals.css'
import { AdvertisingAndAnalytics } from '@/components/AdvertisingAndAnalytics'
import FacebookPixel from '@/components/FacebookPixel'
import { InitializeUser } from '@/components/InitializeUser'
import { PaywallProvider } from '@/components/PaywallModal/PaywallProvider'
import { PrebidProvider } from '@/components/Prebid/PrebidProvider'
import { TurnstileProvider } from '@/components/TurnstileProvider'
import { trpc } from '@/lib/trpcClient'
import { mixpanel } from '@/modules/mixpanel'
import { ModalsProvider } from '@/modules/modals'
import { theme } from '@/modules/theme/theme'
import { supabaseClient } from '@/supabase'
import { getHostUrl } from '@/utils/getHostUrl'
import { GoogleTagProvider } from '@/utils/GoogleTagContext'
import { LayoutProvider } from '@/utils/LayoutContext'
import { useAdWordsReferralTracking } from '@/utils/useAdWordsReferralTracking'
import { useAppRedirect } from '@/utils/useAppRedirect'
import '@fontsource/inter/variable.css'
import { ChakraProvider } from '@chakra-ui/react'
import { SessionContextProvider as SupabaseSessionContextProvider } from '@supabase/auth-helpers-react'
import { Inter } from 'next/font/google'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import 'p-ui/src/utils/bigIntFix'
import NextNProgress from 'nextjs-progressbar'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React, { useEffect, useMemo } from 'react'

const isDev = process.env.NODE_ENV === 'development'

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: `${getHostUrl()}/phog`,
    autocapture: false,
    disable_session_recording: process.env.NODE_ENV !== 'production',
  })
}

const inter = Inter({ subsets: ['latin'] })

function useRouterWithoutPrefetch() {
  const router = useRouter()
  // disable link prefetching as generating pages on the fly is currently quite expensive
  // https://github.com/vercel/next.js/discussions/24437#discussioncomment-3752350
  router.prefetch = async () => undefined
  return router ?? {}
}

function PWebApp({
  Component,
  pageProps,
}: AppProps<{ initialSession: SupabaseSession }>) {
  const { initialSession } = pageProps

  const router = useRouterWithoutPrefetch()
  const { asPath, pathname } = router

  useAppRedirect()
  useAdWordsReferralTracking()

  useEffect(() => {
    function trackRoute() {
      mixpanel.track('route_view', { route: router.route })
    }
    router.events.on('routeChangeComplete', trackRoute)
    return () => router.events.off('routeChangeComplete', trackRoute)
  }, [router])

  useEffect(() => {
    mixpanel.track('route_view', { route: router.route })
  }, [router.route])

  const renderedScripts = useMemo(() => {
    return (
      !isDev && (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-3EN0JHL559"
            strategy="afterInteractive"
          />
          <Script
            referrerPolicy="strict-origin-when-cross-origin"
            src="https://tag.clearbitscripts.com/v1/pk_9645dccf6483612cfa73a479c6d08175/tags.js"
            strategy="afterInteractive"
          />
          <FacebookPixel />

          <Script id="google-analytics" strategy="afterInteractive">
            {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-3EN0JHL559');
      `}
          </Script>
          <Script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />
          <Script
            id="rb2b"
            strategy="afterInteractive"
          >{`!function () {var reb2b = window.reb2b = window.reb2b || [];
    if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];
    reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);
    args.unshift(method);reb2b.push(args);return reb2b;};};
    for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}
    reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;
    script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
    var first = document.getElementsByTagName("script")[0];
    first.parentNode.insertBefore(script, first);};
    reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("0OV0VHLJK46Z");}();`}</Script>
        </>
      )
    )
  }, [])

  const renderedApp = useMemo(() => {
    return (
      <>
        {renderedScripts}
        <Head>
          <meta
            content={`app-id=1351934740, app-argument=https://www.pathway.md${asPath}`}
            name="apple-itunes-app"
          />
        </Head>
        <SupabaseSessionContextProvider
          initialSession={initialSession}
          // @ts-ignore
          supabaseClient={supabaseClient}
        >
          <TurnstileProvider>
            <InitializeUser>
              <GoogleTagProvider>
                <LayoutProvider>
                  <PostHogProvider client={posthog}>
                    <ChakraProvider theme={theme}>
                      <ModalsProvider>
                        <NextNProgress
                          color="#29D"
                          height={matchesHiddenBarRoutes(pathname) ? 0 : 3}
                          options={{ showSpinner: false }}
                          showOnShallow={false}
                          startPosition={0.3}
                          stopDelayMs={200}
                        />
                        <PrebidProvider>
                          <PaywallProvider>
                            <AdvertisingAndAnalytics>
                              <main className={inter.className}>
                                <Component {...pageProps} />
                              </main>
                            </AdvertisingAndAnalytics>
                          </PaywallProvider>
                        </PrebidProvider>
                      </ModalsProvider>
                    </ChakraProvider>
                  </PostHogProvider>
                </LayoutProvider>
              </GoogleTagProvider>
            </InitializeUser>
          </TurnstileProvider>
        </SupabaseSessionContextProvider>
      </>
    )
  }, [renderedScripts, pathname, asPath, initialSession, Component, pageProps])

  return renderedApp
}

const matchesHiddenBarRoutes = (pathname: string) => {
  const matchArr = ['/ai', '/home']
  const match = matchArr.some((match) => pathname.includes(match))
  return match
}

export default trpc.withTRPC(PWebApp)
