import { Box, Stack, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { UpgradeAlertButtonPrimary } from './UpgradeAlertButtonPrimary'
import { UpgradeAlertButtonSecondary } from './UpgradeAlertButtonSecondary'
import { UpgradeAlertContainer } from './UpgradeAlertContainer'
import { UpgradeAlertTitle } from './UpgradeAlertTitle'

export const UpgradeToAskAlert = ({
  questionsRemaining,
  showPaywall,
}: {
  questionsRemaining: number
  showPaywall: () => void
}) => {
  const router = useRouter()

  return (
    <UpgradeAlertContainer>
      <Box flexGrow={1} width="100%">
        <UpgradeAlertTitle>
          <Text as="span">
            You have <b>{questionsRemaining}</b> question
            {questionsRemaining === 1 ? '' : 's'} left today.
          </Text>
          <Text as="span">
            {' '}
            Upgrade or invite colleagues to unlock more questions.
          </Text>
        </UpgradeAlertTitle>
      </Box>
      <Stack direction={['column', 'row']} flex={1} spacing={3} width="100%">
        <UpgradeAlertButtonPrimary onClick={showPaywall}>
          Upgrade Plan
        </UpgradeAlertButtonPrimary>
        <UpgradeAlertButtonSecondary
          as={NextLink}
          href={{
            pathname: router.pathname,
            query: { ...router.query, modal: 'referral' },
          }}
        >
          Invite Colleagues
        </UpgradeAlertButtonSecondary>
      </Stack>
    </UpgradeAlertContainer>
  )
}
