import { trpc } from '@/lib/trpcClient'
import { useProfile } from '@/utils/useProfile'
import {
  Box,
  Button,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { memo, useEffect, useRef } from 'react'

const ENABLE_ANIMATION = false

interface Confetti {
  color: string
  rotation: number
  scale: number
  x: number
  y: number
}

const MotionButton = motion(Button)

const ConfettiPiece = ({ color, rotation, scale, x, y }: Confetti) => (
  <motion.div
    animate={{
      opacity: [1, 0],
      rotate: rotation + 360,
      scale: scale * 0.1,
      x: x + (Math.random() - 0.5) * 200,
      y: y - Math.random() * 200,
    }}
    style={{
      backgroundColor: color,
      borderRadius: '2px',
      height: '10px',
      position: 'absolute',
      rotate: rotation,
      scale,
      width: '10px',
      x,
      y,
      zIndex: 10,
    }}
    transition={{ duration: 3, ease: 'easeOut' }}
  />
)

export const CmeButton = memo(() => {
  const { colorMode } = useColorMode()
  const isLight = colorMode === 'light'
  const { onOpen } = useDisclosure()
  const profile = useProfile()
  const previousCount = useRef<number | null>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff']

  const query = trpc.cme.availablePointCount.useQuery(undefined, {
    enabled: !!profile.profile,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const hasIncreased = Boolean(
    previousCount.current !== null &&
      query.data !== undefined &&
      query.data > previousCount.current,
  )

  useEffect(() => {
    if (query.data !== undefined && !hasIncreased) {
      previousCount.current = query.data
    }
  }, [query.data, hasIncreased])

  const createConfetti = () => {
    if (!buttonRef.current || !hasIncreased || !ENABLE_ANIMATION) return null

    const rect = buttonRef.current.getBoundingClientRect()
    const centerX = rect.width / 2
    const centerY = rect.height / 2
    const confettiCount = 20
    const confetti: Confetti[] = []

    for (let i = 0; i < confettiCount; i++) {
      confetti.push({
        color: colors[Math.floor(Math.random() * colors.length)] ?? '#ff0000',
        rotation: Math.random() * 360,
        scale: Math.random() * 0.5 + 0.5,
        x: centerX,
        y: centerY,
      })
    }

    return confetti.map((props, i) => (
      <ConfettiPiece key={`confetti-${i}`} {...props} />
    ))
  }

  return (
    <Link href="/account/cme">
      <MotionButton
        _hover={{
          backgroundColor: isLight ? 'gray.300' : 'gray.200',
          borderColor: 'transparent',
        }}
        animate={
          hasIncreased && ENABLE_ANIMATION
            ? {
                borderColor: [
                  '#E2E8F0',
                  '#E53E3E',
                  '#3182CE',
                  '#D53F8C',
                  '#E2E8F0',
                ],
              }
            : {}
        }
        as="div"
        backgroundColor={isLight ? 'gray.200' : 'transparent'}
        borderRadius={9999}
        borderWidth={1.5}
        color={isLight ? 'muted' : 'white'}
        fontSize={14}
        gap={1.5}
        onClick={onOpen}
        position="relative"
        px={3}
        py={3.5}
        ref={buttonRef}
        size="xs"
        transition={{ duration: 2 }}
        variant="ghost"
        zIndex={20}
      >
        <Text>CME</Text>
        <Box>
          <Text minW="20px">
            {query.data !== undefined ? query.data.toFixed(1) : '-'}
          </Text>
        </Box>
      </MotionButton>
      {createConfetti()}
    </Link>
  )
})

CmeButton.displayName = 'CmeButton'
