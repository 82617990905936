import type { ComponentProps } from 'react'

import { Text } from '@chakra-ui/layout'
import { useColorMode } from '@chakra-ui/react'

export function UpgradeAlertTitle(props: ComponentProps<typeof Text>) {
  const isLight = useColorMode().colorMode === 'light'

  return (
    <Text
      color={isLight ? 'gray.700' : 'gray.300'}
      fontSize={[13, 14]}
      textAlign={'left'}
      {...props}
    />
  )
}
