import { CmeButton } from '@/components/Layout/CmeButton'
import { useProfile } from '@/utils/useProfile'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  InputGroup,
  Spinner,
  Textarea,
  useColorMode,
} from '@chakra-ui/react'
import { ArrowUp, X } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'

interface AskInputProps {
  inputRef: React.RefObject<HTMLTextAreaElement>
  inputValue: string
  isDisabled?: boolean
  isLoading?: boolean
  onClear?: () => void
  onSubmit?: (value: string) => void
  placeholder?: string
  setInputValue: React.Dispatch<React.SetStateAction<string>>
}

export const AskInput = ({
  inputRef,
  inputValue,
  isDisabled,
  isLoading,
  onClear,
  onSubmit,
  placeholder,
  setInputValue,
}: AskInputProps) => {
  const { colorMode } = useColorMode()
  const isLight = colorMode === 'light'
  const [currentHeight, setCurrentHeight] = useState<number>(0)
  const { isLoggedIn } = useProfile()

  const minHeight = 40
  const maxHeight = 256

  const resizeTextArea = (element: HTMLTextAreaElement) => {
    element.style.height = `${minHeight}px`
    const scrollHeight = element.scrollHeight
    const height = Math.min(scrollHeight, maxHeight)
    element.style.height = `${height}px`
    element.style.overflowY = scrollHeight > maxHeight ? 'scroll' : 'hidden'
    setCurrentHeight(scrollHeight)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
    if (inputRef.current) {
      resizeTextArea(inputRef.current)
    }
  }

  const handleClear = () => {
    setInputValue('')
    if (inputRef.current) {
      inputRef.current.value = ''
      resizeTextArea(inputRef.current)
    }
    onClear?.()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (inputValue.trim().length === 0) return
      e.preventDefault()
      onSubmit?.(inputValue)
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      resizeTextArea(inputRef.current)
    }
  }, [inputRef, inputValue])

  const isMultiline = currentHeight > minHeight

  return (
    <Box
      bg={isLight ? 'gray.75' : 'gray.800'}
      borderRadius={isMultiline ? '15px' : 'full'}
      w="full"
    >
      <Flex position="relative" px={4} py={2}>
        <InputGroup>
          <Textarea
            _focus={{
              boxShadow: 'none',
              outline: 'none',
            }}
            _placeholder={{ color: 'gray.500' }}
            bg="transparent"
            border="none"
            color={isLight ? 'black' : 'white'}
            isDisabled={isDisabled}
            lineHeight={1.5}
            maxH={`${maxHeight}px`}
            minH="10"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            pl={{
              base: 2,
              lg: 4,
            }}
            placeholder={placeholder ?? 'Ask a question...'}
            pr={{
              base: '60px',
              lg: '184px',
            }}
            ref={inputRef}
            resize="none"
            value={inputValue}
          />
          <Box position="absolute" right={0} top={'4px'} w="fit" zIndex={2}>
            <HStack spacing={2}>
              <IconButton
                _hover={{ bg: 'transparent' }}
                alignItems="center"
                aria-label="Clear input"
                color={isLight ? 'black' : 'white'}
                icon={<X size={20} />}
                mr={-2}
                onClick={handleClear}
                size="sm"
                variant="ghost"
                visibility={inputValue ? 'visible' : 'hidden'}
              />
              {isLoggedIn && (
                <Box
                  display={{
                    base: 'none',
                    lg: 'flex',
                  }}
                >
                  <CmeButton />
                </Box>
              )}
              <IconButton
                alignItems="center"
                aria-label="Submit"
                bg="brand.600"
                borderRadius="full"
                color="white"
                display="flex"
                icon={
                  isLoading ? (
                    <Spinner h={4} w={4} />
                  ) : (
                    <ArrowUp size={20} weight="bold" />
                  )
                }
                isDisabled={isDisabled}
                onClick={() => onSubmit?.(inputValue)}
                size="sm"
                variant="unstyled"
              />
            </HStack>
          </Box>
        </InputGroup>
      </Flex>
    </Box>
  )
}
