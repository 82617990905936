// Khudhur here
// These are current Chakra UI colors. I'm violating the DRY principle here
// We could import them dynamically (PWebColors constant in theme.ts)
// But that may cause some depenedency issues down the line, especially if we move away from Chakra UI
export const PWColors = {
  gray: {
    '50': '#F7FAFC',
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#1A202C',
    '900': '#171923',
  },
  brand: {
    '50': '#e8f2ff',
    '100': '#c3d4f1',
    '200': '#9eb5e3',
    '300': '#7793d4',
    '400': '#5270c7',
    '500': '#385fad',
    '600': '#2b4f88',
    '700': '#1d3e62',
    '800': '#0f293e',
    '900': '#01131b',
  },
}
