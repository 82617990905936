import type { AuthError, Session } from '@supabase/supabase-js'

import { PWColors } from '@/../../libs/p-ui/src/theme/webColors'
import { trpc } from '@/lib/trpcClient'
import { useTypedSupabaseClient } from '@/supabase'
import { getHostUrl } from '@/utils/getHostUrl'
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Turnstile } from '@marsidev/react-turnstile'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { FaApple } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { z } from 'zod'
type CredentialsEmail = z.infer<typeof credentialsSchema>
type CredentialsOtp = z.infer<typeof otpSchema>
type CredentialsPassword = z.infer<typeof passwordSchema>

type OAuthProvider = 'apple' | 'azure' | 'google' | 'twitter'

enum Mode {
  email = 'email',
  otp = 'otp',
  password = 'password',
}

const credentialsSchema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
})

const otpSchema = z.object({
  otp: z.preprocess(
    (val) => String(val).trim(),
    z.string().length(6, { message: 'Invalid OTP' }),
  ),
})

const passwordSchema = z.object({
  password: z.string(),
})

const schemas = {
  [Mode.email]: credentialsSchema,
  [Mode.otp]: otpSchema,
  [Mode.password]: passwordSchema,
}

export const SignInCompact = () => {
  const supabase = useTypedSupabaseClient()
  const [email, setEmail] = useState('')
  const [mode, setMode] = useState(Mode.email)
  const router = useRouter()
  const [captchaToken, setCaptchaToken] = useState('')
  const utils = trpc.useUtils()

  const redirectUrl =
    getHostUrl() + `/auth/signed-in?redirectedFrom=${router.asPath}`

  const { colorMode } = useColorMode()
  const isLight = colorMode === 'light'

  const schema = useMemo(() => schemas[mode], [mode])

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setValue,
  } = useForm<CredentialsEmail & CredentialsOtp & CredentialsPassword>({
    resolver: zodResolver(schema),
  })

  const [authError, setAuthError] = useState<AuthError | null>(null)

  function getRedirect(redirectUrl: string, session: Session) {
    return (
      redirectUrl +
      `#access_token=${session?.access_token}&expires_in=3600&refresh_token=${session?.refresh_token}&token_type=bearer&type=magiclink`
    )
  }

  const getScopes = (provider: OAuthProvider) => {
    switch (provider) {
      case 'apple':
        return 'name email'
      case 'azure':
        return 'email'
      default:
        return undefined
    }
  }

  const onSubmit: SubmitHandler<
    CredentialsEmail | CredentialsOtp | CredentialsPassword
  > = async (args) => {
    if (mode === 'email' && (args as CredentialsEmail).email) {
      const mode = await utils.profiles.authMode.fetch({
        email: (args as CredentialsEmail).email,
      })
      setEmail((args as CredentialsEmail).email)
      return mode === 'otp'
        ? requestOtp(args as CredentialsEmail)
        : setMode(Mode.password)
    } else if ((args as CredentialsEmail).email) {
      return requestOtp(args as CredentialsEmail)
    } else if ((args as CredentialsOtp).otp) {
      return verifyOtp(args as CredentialsOtp)
    } else if ((args as CredentialsPassword).password) {
      return signInWithPassword(args as CredentialsPassword)
    }
  }
  async function requestOtp(args: CredentialsEmail) {
    const { error } = await supabase.auth.signInWithOtp({
      email: args.email,
      options: {
        captchaToken,
        emailRedirectTo: redirectUrl,
      },
    })

    if (error) {
      setAuthError(error)
      return
    }
    setAuthError(null)
    setEmail(args.email)
    setMode(Mode.otp)
  }

  const reset = () => {
    setEmail('')
    setMode(Mode.email)
    setAuthError(null)
    setValue('email', '')
  }

  const signInWithProvider = (provider: OAuthProvider) => {
    return supabase.auth.signInWithOAuth({
      options: {
        redirectTo: redirectUrl,
        scopes: getScopes(provider),
      },
      provider,
    })
  }

  async function signInWithPassword(args: CredentialsPassword) {
    const { data, error: authError } = await supabase.auth.signInWithPassword({
      email,
      options: {},
      password: args.password,
    })

    if (authError) {
      setAuthError(authError)
      return
    }
    setAuthError(null)
    if (data?.session) {
      window.document.location = getRedirect(redirectUrl, data.session)
    }
  }

  async function verifyOtp(args: CredentialsOtp) {
    const { data, error } = await supabase.auth.verifyOtp({
      email,
      token: (args as CredentialsOtp).otp,
      type: 'email',
    })
    if (error) {
      setAuthError(error)
      return
    }
    if (data?.session) {
      window.document.location = getRedirect(redirectUrl, data.session)
    }
  }

  return (
    <Box width={'100%'}>
      <Turnstile
        injectScript={process.env.NODE_ENV === 'production'}
        onSuccess={(token) => {
          setCaptchaToken(token)
        }}
        siteKey={process.env.NEXT_PUBLIC_CF_SITE_KEY as string}
        style={{ left: '-9999px', position: 'absolute' }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box width="100%">
          <Stack spacing="3">
            <Stack spacing="5">
              {mode === Mode.otp && (
                <FormControl isInvalid={!!errors.otp}>
                  <FormLabel htmlFor="email">Verification Code</FormLabel>
                  <Input
                    fontSize={14}
                    id="otp"
                    type="text"
                    {...register('otp')}
                    _placeholder={{
                      color: isLight ? PWColors.gray[400] : PWColors.gray[500],
                    }}
                    placeholder="E.g. 123456"
                  />
                  <FormErrorMessage>{errors.otp?.message}</FormErrorMessage>
                </FormControl>
              )}
              {mode === Mode.password && (
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    id="password"
                    placeholder="********"
                    required
                    type="password"
                    {...register('password', { required: true })}
                    _placeholder={{
                      color: isLight ? PWColors.gray[400] : PWColors.gray[500],
                    }}
                  />
                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                </FormControl>
              )}

              {mode === Mode.email && (
                <FormControl isInvalid={!!errors.email}>
                  <Input
                    autoComplete="username"
                    fontSize={14}
                    id="email"
                    placeholder="Enter your email address..."
                    type="email"
                    {...register('email')}
                    _placeholder={{
                      color: isLight ? PWColors.gray[400] : PWColors.gray[500],
                    }}
                  />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
              )}
            </Stack>
            <Stack spacing="6">
              {authError !== null && (
                <Text color="red" fontSize="sm" textAlign="center">
                  {authError.message}
                </Text>
              )}
              <Button
                fontSize={14}
                isLoading={isSubmitting}
                loadingText="Loading..."
                type="submit"
                variant="primary"
              >
                {mode === Mode.email ? 'Continue with email' : 'Continue'}
              </Button>
            </Stack>
            {mode === Mode.email && (
              <Box paddingY="2" position="relative">
                <Divider borderColor={isLight ? 'gray.200' : 'gray.800'} />
                <AbsoluteCenter
                  bg={{
                    base: isLight ? 'bg-shell' : 'gray.900',
                    sm: isLight ? 'white' : 'gray.900',
                  }}
                  px="4"
                >
                  <Text fontSize="xs" fontWeight="semibold" opacity="0.5">
                    OR
                  </Text>
                </AbsoluteCenter>
              </Box>
            )}
            {mode === Mode.email && (
              <>
                <HStack spacing="3">
                  <IconButton
                    _hover={{
                      backgroundColor: isLight ? 'gray.100' : 'gray.800',
                    }}
                    aria-label="Sign in with Google"
                    backgroundColor={'transparent'}
                    borderColor={isLight ? 'gray.200' : 'gray.800'}
                    borderWidth={1}
                    colorScheme="brand"
                    flexGrow="1"
                    fontSize="24px"
                    icon={<FcGoogle />}
                    onClick={() => signInWithProvider('google')}
                  />
                  <IconButton
                    _hover={{
                      backgroundColor: isLight ? 'gray.100' : 'gray.800',
                    }}
                    aria-label="Sign in with Apple"
                    backgroundColor={'transparent'}
                    borderColor={isLight ? 'gray.200' : 'gray.800'}
                    borderWidth={1}
                    colorScheme="brand"
                    flexGrow="1"
                    fontSize="24px"
                    icon={<FaApple />}
                    onClick={() => signInWithProvider('apple')}
                  />
                </HStack>
              </>
            )}
          </Stack>
        </Box>
      </form>
      {mode === Mode.otp && (
        <Stack mt={6} spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
              <HStack justify="center" spacing="1">
                <Button colorScheme="brand" onClick={reset} variant="link">
                  Back to sign in
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}
