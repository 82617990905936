import { useProfile } from '@/utils/useProfile'
import {
  Box,
  Button,
  HStack,
  Text,
  useColorMode,
  useOutsideClick,
} from '@chakra-ui/react'
import {
  Book,
  Calculator,
  Chat,
  Clock,
  DiceFive,
  GitMerge,
  Globe,
  Pencil,
  Pill,
  Stethoscope,
  Table,
  X,
} from '@phosphor-icons/react'
import { captureException } from '@sentry/nextjs'
import { useInfiniteQuery } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { parseUserSpecialities } from 'p-common/src/utils/parseUserSpecialities'
import { suggestionsQueryFn } from 'p-common/src/utils/suggestionsQueryFn'
import { useMemo, useRef, useState } from 'react'

const MotionBox = motion(Box)
const MAX_VISIBLE_PILLS = 4

const useSuggestions = () => {
  const { profile } = useProfile()

  const userSpecialtyIds = useMemo(() => {
    const specialties = parseUserSpecialities(profile?.specialties).map(
      (s) => s.id,
    )
    return specialties.length > 0 ? specialties : ['recrje5HglPoet026']
  }, [profile?.specialties])

  const { data } = useInfiniteQuery<{ suggestedQuestions: string[] }>({
    enabled: !!userSpecialtyIds,
    queryFn: async ({ pageParam = 0 }) =>
      suggestionsQueryFn({
        onError: (error) => captureException(error),
        pageParam,
        userSpecialtyIds,
      }),
    queryKey: ['getSuggestionsForSpecialty', userSpecialtyIds],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const suggestions = useMemo(() => {
    return data?.pages.flatMap((page) => page.suggestedQuestions) ?? []
  }, [data])

  return suggestions
}

const usePills = () => {
  const suggestions = useSuggestions()

  const allPills = useMemo(
    () => [
      {
        color: 'blue.600',
        icon: Clock,
        includePrefixInAction: false,
        suggestions:
          suggestions.length > 0
            ? suggestions.slice(0, 3)
            : [
                'Updated ACC/AHA guidelines for Afib anticoag',
                'IDSA HAP/VAP abx recommendations',
                'KDIGO CKD-MBD management',
              ],
        text: "What's new",
      },
      {
        color: 'blue.600',
        icon: DiceFive,
        includePrefixInAction: false,
        suggestions: [
          'Summarize studies of ustekinumab in CD',
          'Summarize RCTs of steroids in septic shock',
          'Summarize the PARAGON-HF study',
        ],
        text: 'Summarize study',
      },
      {
        color: 'blue.600',
        icon: Stethoscope,
        includePrefixInAction: false,
        suggestions: [
          'Tx of refractory CHF & preserved EF',
          'Tx of ARDS on high PEEP/prone positioning',
          'Tx of DKA & severe anion gap',
        ],
        text: 'Manage patient',
      },
      {
        color: 'blue.600',
        icon: GitMerge,
        includePrefixInAction: false,
        suggestions: [
          'DDx for 65M w/ AKI, new HTN, peripheral eosinophilia',
          'DDx for 45F w/ recurrent DVT on therapeutic anticoag',
          'DDx for 72M w/ unexplained weight loss, night sweats',
        ],
        text: 'Find differential',
      },
      {
        color: 'blue.600',
        icon: Pencil,
        suggestions: [
          'SOAP note for diabetic foot exam',
          'SOAP note for complicated CDI on oral vanc',
          'SOAP note for 45F w/ recurrent DVT on therapeutic anticoag',
        ],
        text: 'Draft note',
      },
      {
        color: 'blue.600',
        icon: Calculator,
        includePrefixInAction: false,
        suggestions: [
          'Calculate eGFR by CKD-EPI for 67F w/ creat 1.2',
          'Alvarado score in 65 yo F with only WBC 40 and L shift',
          'Calculate anion gap if Na 140, Cl 105, and HCO3 20',
        ],
        text: 'Use calculator',
      },
      {
        color: 'blue.600',
        icon: Pill,
        includePrefixInAction: false,
        suggestions: [
          'Dose of lemborexant for insomnia',
          'Dose of finasteride for hair loss',
          'Dose of apixaban for AF in 85M with eGFR 25',
        ],
        text: 'Find dose',
      },
      {
        color: 'blue.600',
        icon: Table,
        includePrefixInAction: false,
        suggestions: [
          'Table comparing allopurinol vs. febuxostat',
          'Summarize Entresto RCTs using table',
          'Table summary of hypercalcemia ddx',
        ],
        text: 'Compare w/ table',
      },
      {
        color: 'blue.600',
        icon: Book,
        includePrefixInAction: false,
        suggestions: [
          'Counsel patient on how to inject semaglutide',
          'Counsel patient on diet during pregnancy',
          'How to deliver bad news to patients',
        ],
        text: 'Counsel patient',
      },
      {
        color: 'blue.600',
        icon: Chat,
        includePrefixInAction: false,
        suggestions: [
          'Create quiz on indications for CABG',
          'Create quiz on ddx of hemolytic anemia',
          'Create quiz on treatment of SLE',
        ],
        text: 'Quiz me',
      },
      {
        color: 'blue.600',
        icon: Globe,
        includePrefixInAction: false,
        suggestions: [
          'traitement préventif des migraines',
          '结核病治疗',
          'Behandlung von Tuberkulose',
        ],
        text: 'Other languages',
      },
    ],
    [suggestions],
  )

  const [isExpanded, setIsExpanded] = useState(false)

  const randomPills = useMemo(() => {
    const whatsNewPill = allPills[0]
    const otherPills = allPills.slice(1)
    const shuffled = [...otherPills].sort(() => Math.random() - 0.5)
    return [
      whatsNewPill,
      ...shuffled.slice(0, MAX_VISIBLE_PILLS - 1),
    ] as typeof allPills
  }, [allPills])

  return {
    allPills,
    isExpanded,
    setIsExpanded,
    visiblePills: isExpanded ? allPills : randomPills,
  }
}

export const SuggestionsPills = ({
  onSuggestionClick,
}: {
  onSuggestionClick: (value: string) => void
}) => {
  const { colorMode } = useColorMode()
  const isLight = colorMode === 'light'
  const { allPills, isExpanded, setIsExpanded, visiblePills } = usePills()
  const expandedPillRef = useRef<HTMLButtonElement | null>(null)

  const [selectedPill, setSelectedPill] = useState<(typeof allPills)[0] | null>(
    null,
  )

  const handlePillClick = (pill: (typeof allPills)[0]) => {
    if (pill.text === 'More') {
      setIsExpanded(true)
    } else {
      setSelectedPill(pill)
    }
  }

  const handleSuggestionClick = (suggestion: string) => {
    setSelectedPill(null)
    onSuggestionClick(suggestion)
  }

  const handleBackClick = () => {
    setSelectedPill(null)
  }

  useOutsideClick({
    handler: handleBackClick,
    ref: expandedPillRef,
  })

  return (
    <MotionBox position="relative">
      <MotionBox
        animate={{ opacity: 1 }}
        display="flex"
        flexWrap={{
          base: 'wrap',
          lg: isExpanded ? 'wrap' : 'nowrap',
        }}
        gap={2}
        justifyContent="center"
        mt={6}
      >
        {visiblePills.map((pill) => (
          <MotionBox
            _hover={{
              opacity: 0.7,
            }}
            alignItems="center"
            bg={isLight ? 'blue.25' : 'gray.900'}
            border="1px solid"
            borderColor={isLight ? 'gray.200' : 'gray.700'}
            borderRadius="full"
            cursor="pointer"
            display="flex"
            key={pill.text}
            onClick={() => handlePillClick(pill)}
            px={3}
            py={1}
          >
            <Box
              as={pill.icon}
              color={isLight ? pill.color : 'gray.400'}
              mr={1.5}
              size={17}
            />
            <Text
              color={isLight ? 'black' : 'white'}
              fontSize="sm"
              suppressHydrationWarning
              whiteSpace="nowrap"
            >
              {pill.text}
            </Text>
          </MotionBox>
        ))}
        {!isExpanded && (
          <MotionBox
            _hover={{
              opacity: 0.7,
            }}
            alignItems="center"
            bg={isLight ? 'white' : 'gray.900'}
            border="1px solid"
            borderColor={isLight ? 'gray.200' : 'gray.700'}
            borderRadius="full"
            cursor="pointer"
            display="flex"
            onClick={() => setIsExpanded(true)}
            px={3}
            py={1}
          >
            <Text color={isLight ? 'black' : 'white'} fontSize="sm">
              More
            </Text>
          </MotionBox>
        )}
      </MotionBox>

      {selectedPill && (
        <MotionBox
          animate={{ opacity: 1, y: 0 }}
          bg={isLight ? 'white' : 'gray.900'}
          borderColor={isLight ? 'gray.200' : 'gray.700'}
          borderRadius={14}
          borderWidth={1}
          initial={{ opacity: 0, y: -20 }}
          left="0"
          mt={3}
          position="absolute"
          px={3}
          py={1}
          ref={expandedPillRef}
          textAlign="left"
          top="-3"
          transition={{ duration: 0.3 }}
          width="100%"
          zIndex={1}
        >
          <MotionBox
            alignItems="center"
            borderColor={isLight ? 'gray.200' : 'gray.700'}
            display="flex"
            justifyContent="space-between"
            p={1}
          >
            <HStack>
              <Box
                as={selectedPill.icon}
                color={isLight ? selectedPill.color : 'gray.400'}
                mr={1}
                size={16}
              />

              <Text color={isLight ? 'gray.800' : 'gray.400'}>
                {selectedPill.text}
              </Text>
            </HStack>
            <Button
              _hover={{
                opacity: 0.8,
              }}
              onClick={handleBackClick}
              size="sm"
              variant="unstyled"
            >
              <X size={20} />
            </Button>
          </MotionBox>
          {selectedPill.suggestions.map((suggestion) => (
            <MotionBox
              _hover={{ opacity: 0.8 }}
              cursor="pointer"
              key={suggestion}
              onClick={() => {
                handleSuggestionClick(
                  selectedPill.includePrefixInAction
                    ? `${selectedPill.text} ${suggestion}`
                    : suggestion,
                )
              }}
              px={1}
              py={2}
            >
              <Text color={isLight ? 'gray.600' : 'white'}>{suggestion}</Text>
            </MotionBox>
          ))}
        </MotionBox>
      )}
    </MotionBox>
  )
}
