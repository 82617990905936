import type { ComponentProps } from 'react'

import { Box, useColorMode, VStack } from '@chakra-ui/react'

export function UpgradeAlertContainer(props: ComponentProps<typeof Box>) {
  const isLight = useColorMode().colorMode === 'light'

  return (
    <VStack alignItems="flex-start" gap={3}>
      <Box
        alignItems={'center'}
        bg={isLight ? 'white' : 'gray.800'}
        borderColor={isLight ? 'gray.200' : 'gray.700'}
        borderRadius={15}
        borderWidth={'1px'}
        display="flex"
        flexDir={['column', 'row']}
        fontSize="sm"
        gap={[4, 3]}
        justifyContent={'flex-start'}
        mb={4}
        px={[6, 5]}
        py={3}
        width="100%"
        zIndex={2}
        {...props}
      />
    </VStack>
  )
}
