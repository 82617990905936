import { HomeInputAndSuggestionPills } from '@/components/HomeInputAndSuggestionPills'
import { Box, Container, Heading } from '@chakra-ui/react'

import { LogoGrid } from '../LogoGrid/LogoGrid'

export const HeroSectionQuestion = () => {
  return (
    <>
      <Box bg="white" isolation="isolate" position="relative" pt={6}>
        <Box pt={{ base: 6, sm: 6 }}>
          <Container maxW="7xl" px={{ base: 6, lg: 8 }}>
            <Box
              maxW="3xl"
              mx="auto"
              py={{ base: 6, lg: 12 }}
              textAlign="center"
            >
              <Heading
                color="gray.900"
                fontSize={{ base: '3xl', lg: '36px' }}
                fontWeight="semibold"
                letterSpacing="tight"
                lineHeight={{ lg: '1.0625' }}
                mb={10}
                mt={{ base: 12, lg: 24 }}
              >
                Medical knowledge at your fingertips
              </Heading>

              <HomeInputAndSuggestionPills />
            </Box>

            {/* Logo Cloud*/}
            <Box py={{ base: 16, lg: 32 }}>
              <LogoGrid />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  )
}
