import type { Dispatch, SetStateAction } from 'react'

import { useProfile } from '@/utils/useProfile'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

type TurnstileContextValue = {
  resetTurnstile: () => void
  setToken: Dispatch<SetStateAction<null | string>>
  token: null | string
}

export const TurnstileContext = createContext<TurnstileContextValue | null>(
  null,
)

const sitekey = process.env.NEXT_PUBLIC_CF_SITE_KEY ?? ''

const getTurnstileOrError = () => {
  if (!window.turnstile) {
    throw new Error('Turnstile not loaded')
  }
  return window.turnstile
}

export function TurnstileProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState<null | string>(null)
  const router = useRouter()

  const { isLoggedOut } = useProfile()

  useEffect(() => {
    if (isLoggedOut || router.asPath.includes('/contact')) {
      let widgetId: string
      window.onloadTurnstileCallback = () => {
        const turnstile = getTurnstileOrError()
        widgetId = turnstile.render('.cf-turnstile', {
          callback: (data) => {
            setToken(data)
          },
          sitekey,
        })
      }
      window.onresetTurnstileCallback = () => {
        const turnstile = getTurnstileOrError()
        setToken(null)
        turnstile.reset(widgetId)
      }
    }
  }, [isLoggedOut, router.asPath, setToken])

  return (
    <TurnstileContext.Provider
      value={useMemo(
        () => ({
          resetTurnstile: () => window.onresetTurnstileCallback(),
          setToken,
          token,
        }),
        [token],
      )}
    >
      {children}
      <Script
        defer
        src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadTurnstileCallback"
      ></Script>
      <div className="cf-turnstile"></div>
    </TurnstileContext.Provider>
  )
}

export function useTurnstileContext() {
  const turnstileContext = useContext(TurnstileContext)
  if (!turnstileContext) {
    throw new Error(
      'useTurnstileContext must be used within a TurnstileProvider',
    )
  }
  return turnstileContext
}
