import { shuffle } from 'lodash'

interface SuggestedQuestionsPage {
  nextCursor: string
  suggestedQuestions: string[]
}

const fallbackSuggestions = [
  'T2DM 2024 guidelines',
  'Diagnosis of chronic limb ischemia',
  'Management of severe asthma',
]

const limit = 3

export const suggestionsQueryFn = async ({
  onError,
  userSpecialtyIds,
}: {
  onError?: (error: Error) => void
  pageParam: number | string | undefined
  userSpecialtyIds: string[]
}) => {
  const specialities = JSON.stringify(userSpecialtyIds)

  const url = `${
    process.env['NEXT_PUBLIC_P_ASSISTANT_HOST'] || 'http://localhost:5001'
  }/v7/suggestBySpecialityIds?input=${specialities}&limit=${limit}`

  const response = await fetch(url, {
    signal: AbortSignal.timeout(5000),
  })
    .then(async (res) => {
      const data = (await res.json()) as SuggestedQuestionsPage
      if (data.suggestedQuestions.length < 3) {
        return {
          ...data,
          suggestedQuestions: data.suggestedQuestions.concat(
            shuffle(fallbackSuggestions).slice(
              0,
              limit - data.suggestedQuestions.length,
            ),
          ),
        }
      }
      return data
    })
    .catch((error) => {
      if (onError) {
        onError(error)
      }
      return {
        nextCursor: '',
        suggestedQuestions: shuffle(fallbackSuggestions),
      }
    })

  return response
}
