import { trpc } from '@/lib/trpcClient'
import { useAskAiQuestion } from '@/utils/useAskAIQuestion'
import { Box } from '@chakra-ui/react'
import { useSession } from '@supabase/auth-helpers-react'
import { motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'

import { AskInput } from './Assistant/components/AskInput'
import { SuggestionsPills } from './Assistant/components/SuggestionsPills'
import { LoginToAskAlert } from './Assistant/components/UpgradeAlert/LoginToAskAlert'
import { UpgradeToAskAlert } from './Assistant/components/UpgradeAlert/UpgradeToAskAlert'
import { usePaywallContext } from './PaywallModal/PaywallProvider'

const MotionBox = motion(Box)

export const HomeInputAndSuggestionPills = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const session = useSession()

  const authorization = trpc.ai.authorization.useQuery(undefined, {
    enabled: !!session,
  })
  const canAskQuestion = authorization.data?.canAsk !== false
  const askAiQuestion = useAskAiQuestion()
  const { showPaywall } = usePaywallContext()

  const onInputSubmit = (input: string) => {
    if (input.trim().length === 0) return
    setIsSubmitting(true)
    askAiQuestion(input)
  }

  const placeholder = useMemo(() => {
    if (canAskQuestion) return undefined
    else if (session?.user.is_anonymous) {
      return 'Please sign in to ask more questions'
    } else {
      return 'Upgrade to ask more questions'
    }
  }, [canAskQuestion, session])

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <MotionBox
      animate={{ opacity: 1, y: 0 }}
      flex={1}
      initial={{ opacity: 0, y: -10 }}
      transition={{ delay: 0.3, duration: 0.3 }}
    >
      <AskInput
        inputRef={inputRef}
        inputValue={inputValue}
        isDisabled={!canAskQuestion}
        isLoading={isSubmitting}
        onClear={() => setInputValue('')}
        onSubmit={onInputSubmit}
        placeholder={placeholder}
        setInputValue={setInputValue}
      />
      <Box mt={4}>
        {canAskQuestion && (
          <SuggestionsPills
            onSuggestionClick={(i) => {
              setInputValue(i)
              onInputSubmit(i)
            }}
          />
        )}
        {!canAskQuestion && session?.user?.is_anonymous === false && (
          <UpgradeToAskAlert
            questionsRemaining={authorization.data?.questionsRemaining ?? 0}
            showPaywall={showPaywall}
          />
        )}
        {!canAskQuestion && session?.user?.is_anonymous === true && (
          <LoginToAskAlert
            questionsRemaining={authorization.data?.questionsRemaining ?? 0}
          />
        )}
      </Box>
    </MotionBox>
  )
}
