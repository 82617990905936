import { useRouter } from 'next/router'

export function useAskAiQuestion() {
  const router = useRouter()

  return function (question: string) {
    void router.push(
      `/ai/history/${self.crypto.randomUUID()}?question=${encodeURIComponent(
        question,
      )}`,
      undefined,
      {
        shallow: true,
      },
    )
  }
}
