import type { GlobalStyleProps } from '@chakra-ui/theme-tools'

import { checkboxAnatomy, inputAnatomy, radioAnatomy } from '@chakra-ui/anatomy'
import {
  type theme as baseTheme, // theme as baseTheme,
  type ChakraTheme,
  defineStyle,
  defineStyleConfig,
  extendTheme,
  withDefaultColorScheme,
  withDefaultProps,
} from '@chakra-ui/react'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { pathwayBrandColorPalette } from 'p-common/src/constants/style'

import { theme as proTheme } from './pro-theme'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const checkboxStyles = createMultiStyleConfigHelpers(checkboxAnatomy.keys)
const radioStyles = createMultiStyleConfigHelpers(radioAnatomy.keys)

const pathwayTheme: Partial<ChakraTheme> = {
  colors: {
    ...proTheme.colors,
    amber: {
      50: '#fffbeb',
      100: '#fef3c7',
      200: '#fde68a',
      300: '#fcd34d',
      400: '#fbbf24',
      500: '#f59e0b',
      600: '#d97706',
      700: '#b45309',
      800: '#92400e',
      900: '#78350f',
      950: '#451a03',
    },
    blue: pathwayBrandColorPalette,
    brand: pathwayBrandColorPalette,
    gray: {
      // code such as PaywallFooter relies on these values being in HEX format
      50: '#f9fafb',
      75: '#f6f7f9',
      100: '#f3f4f6',
      200: '#e5e7eb',
      300: '#d1d5db',
      400: '#9ca3af',
      500: '#6b7280',
      600: '#4b5563',
      700: '#374151',
      800: '#1f2937',
      900: '#111827',
      950: '#1a1a1a',
    },
    orange: {
      50: '#fff7ed',
      100: '#ffedd5',
      200: '#fed7aa',
      300: '#fdba74',
      400: '#fb923c',
      500: '#f97316',
      600: '#ea580c',
      700: '#c2410c',
      800: '#9a3412',
      900: '#7c2d12',
      950: '#431407',
    },
    slate: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
      950: '#020617',
    },
    zincGray: {
      50: '#fafafa',
      100: '#f4f4f5',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b',
      950: '#09090b',
    },
  },
  components: {
    // Example: override config of a built-in element
    Button: defineStyleConfig({
      baseStyle: {
        fontWeight: 500,
      },
      variants: {
        primary: defineStyle((props) => ({
          _disabled: {
            pointerEvents: 'none',
          },
          _hover: {
            background: props.colorMode === 'dark' ? 'gray.700' : 'brand.700',
          },
          background: props.colorMode === 'dark' ? 'brand.500' : 'brand.600',
          color: 'white',
        })),
        primaryLight: defineStyle((props) => ({
          _disabled: {
            pointerEvents: 'none',
          },
          _hover: {
            background: props.colorMode === 'dark' ? 'brand.800' : 'brand.100',
            color: props.colorMode === 'dark' ? 'white' : 'brand.700',
          },
          background: props.colorMode === 'dark' ? 'brand.900' : 'brand.50',
          color: props.colorMode === 'dark' ? 'brand.100' : 'brand.500',
        })),
        primaryOutline: defineStyle((props) => ({
          _disabled: {
            pointerEvents: 'none',
          },
          _hover: {
            background: props.colorMode === 'dark' ? 'gray.700' : 'brand.500',
            color: 'white',
          },
          borderColor: props.colorMode === 'dark' ? 'brand.700' : 'brand.500',
          borderWidth: 1,
          color: props.colorMode === 'dark' ? 'brand.700' : 'brand.500',
        })),
        solid: defineStyle((props) => ({
          _hover: {
            _disabled: {
              background: props.colorMode === 'dark' ? 'gray.700' : 'gray.300',
            },
            background: props.colorMode === 'dark' ? 'gray.700' : 'gray.300',
          },
          background: props.colorMode === 'dark' ? 'gray.800' : 'gray.200',
          color: props.colorMode === 'dark' ? 'gray.200' : 'gray.800',
        })),
      },
    }),
    Checkbox: {
      baseStyle: (props: GlobalStyleProps) => ({
        control: {
          borderColor: mode('gray.300', 'gray.500')(props),
        },
      }),
      sizes: {
        xl: checkboxStyles.definePartsStyle({
          control: defineStyle({
            boxSize: 6,
          }),
          label: defineStyle({
            fontSize: 'xl',
            marginLeft: 6,
          }),
        }),
      },
    },
    Heading: {
      sizes: {
        sm: {
          // 17px is the heading size used for headings in the resource bodies on mobile.
          // It has been added here for consistency
          fontSize: '17px',
        },
      },
    },
    Input: defineMultiStyleConfig({
      variants: {
        outline: definePartsStyle((props) => ({
          field: {
            backgroundColor:
              props.colorMode === 'dark' ? 'gray.800' : 'gray.50',
            fontSize: 'md',
          },
        })),
      },
    }),
    Radio: {
      baseStyle: (props: GlobalStyleProps) => ({
        control: {
          borderColor: mode('gray.300', 'gray.500')(props),
        },
      }),
      sizes: {
        xl: radioStyles.definePartsStyle({
          control: defineStyle({
            boxSize: 6,
          }),
          label: defineStyle({
            fontSize: 'xl',
            marginLeft: 6,
          }),
        }),
      },
    },
    Select: {
      variants: {
        outline: definePartsStyle((props) => ({
          field: {
            borderColor: props.colorMode === 'dark' ? 'gray.600' : 'gray.200',
          },
        })),
      },
    },
    Tabs: defineStyleConfig({
      defaultProps: {
        variant: 'soft-rounded',
      },
      variants: defineStyle({
        'soft-rounded': {
          tabpanel: {
            px: 0,
            py: 0,
          },
        },
      }),
    }),
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
  semanticTokens: {
    colors: {
      'bg-box': {
        _dark: 'gray.700',
        default: 'gray.50',
      },
      'bg-page': {
        _dark: 'gray.700',
        default: 'white',
      },
      'bg-shell': {
        _dark: 'gray.900',
        default: 'white',
      },
    },
  },
}

export const theme = extendTheme(
  {
    breakpoints: {
      '1400w': '1400px',
    },
  },
  pathwayTheme,
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: ['Link', 'Checkbox'],
  }),
  withDefaultProps({
    components: ['Link'],
    defaultProps: {
      colorScheme: 'brand.500',
    },
  }),
  // baseTheme,
  proTheme,
)

export const PathwayWebColors = pathwayTheme.colors as {
  brand: typeof pathwayBrandColorPalette
} & typeof baseTheme.colors
