import { SignInCompact } from '@/components/SignIn/SignInCompact'
import { useProfile } from '@/utils/useProfile'
import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Fade,
  IconButton,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const key = 'signUpBannerDismissed'

export function AuthBox() {
  const { colorMode } = useColorMode()
  const [show, setShow] = useState(false)
  const { isLoggedOut } = useProfile()

  const isMobile = useBreakpointValue(
    { base: true, lg: false, md: false },
    { fallback: 'lg' },
  )

  useEffect(() => {
    if (!isLoggedOut) return
    const signUpBannerDismissed = sessionStorage.getItem(key)
    if (signUpBannerDismissed) return
    setShow(true)
  }, [isLoggedOut])

  function dismiss() {
    setShow(false)
    sessionStorage.setItem(key, 'q')
  }

  if (!show || isMobile) return null

  return (
    <Box bottom={6} position={'fixed'} right={6} width={348} zIndex={10}>
      <Fade in={true} unmountOnExit={true}>
        <Box transition="0.3s ease transform" width="100%">
          <Box
            bg={colorMode === 'light' ? 'white' : 'gray.900'}
            borderColor={colorMode === 'light' ? 'gray.200' : 'gray.800'}
            borderRadius={9}
            borderWidth={'1px'}
            boxShadow={`0 -10px 30px 0px rgba(0,0,0,${
              colorMode === 'light' ? 0.1 : 0.3
            })`}
            pt={10}
            px={8}
          >
            <Stack position="absolute" right={3} top={3}>
              <IconButton
                _hover={{
                  opacity: 1,
                }}
                aria-label="toggle auth wall"
                icon={<CloseIcon fontSize={11} />}
                onClick={dismiss}
                opacity={0.5}
                size={'xs'}
                variant="ghost"
              />
            </Stack>

            <Stack alignItems={'center'} pb={8} spacing={3}>
              <Stack spacing="0.75">
                <Text fontSize={17} fontWeight={'bold'} textAlign={'center'}>
                  Sign in or create a free account
                </Text>
                <Text fontSize={13} textAlign={'center'}>
                  {'Unlock more AI questions, history, and more.'}
                </Text>
              </Stack>
              <Box flex={1} width="100%">
                <SignInCompact />
                <Text fontSize="2xs" mt={3} px={4} textAlign={'center'}>
                  By signing up, you agree to our <br />
                  <Link
                    color="brand.500"
                    href="https://www.pathway.md/terms"
                    target="_blank"
                  >
                    terms of use
                  </Link>{' '}
                  and{' '}
                  <Link
                    color="brand.500"
                    href="https://www.pathway.md/privacy"
                    target="_blank"
                  >
                    privacy policy
                  </Link>
                  .
                </Text>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Box>
  )
}
