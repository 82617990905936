import type { ComponentProps } from 'react'

import { Button, useColorMode } from '@chakra-ui/react'

export function UpgradeAlertButtonPrimary(
  props: ComponentProps<typeof Button>,
) {
  const isLight = useColorMode().colorMode === 'light'

  return (
    <Button
      _hover={{
        bg: isLight ? 'black' : 'gray.100',
      }}
      bg={isLight ? 'blue.600' : 'blue.600'}
      borderRadius={'lg'}
      color={isLight ? 'white' : 'gray.900'}
      fontSize={[12, 14]}
      size={['sm', 'sm']}
      width={['100%', 'auto']}
      {...props}
    />
  )
}
