import type { ReactNode } from 'react'

import { mixpanel } from '@/modules/mixpanel'
import { supabaseClient } from '@/supabase'
import { setSentrySessionTracking } from '@/utils/setSentrySessionTracking'
import { useProfile } from '@/utils/useProfile'
import { useSessionContext } from '@supabase/auth-helpers-react'
import posthog from 'posthog-js'
import { useEffect } from 'react'

export function InitializeUser(props: { children: ReactNode }) {
  const { profile } = useProfile()
  const sessionContext = useSessionContext()

  useEffect(() => {
    async function initializeAnonUser() {
      if (!sessionContext.isLoading && !sessionContext.session) {
        await supabaseClient.auth.signInAnonymously()
      }
    }
    void initializeAnonUser()
  }, [sessionContext.session, sessionContext.isLoading])

  useEffect(() => {
    // intialize Sentry with user info if available
    async function initializeUser() {
      if (profile) {
        mixpanel.identify(profile.distinctId)
        setSentrySessionTracking(profile)
        posthog.identify(profile.distinctId, {
          email: profile.email,
        })
      }
    }

    void initializeUser()
  }, [profile])

  return props.children
}
